import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const WigforWomen = () => {
  const seoData = {
    title: "Wigs for Women in Delhi - Radiance Hair Studio",
    description:
      "Explore a stunning range of Wigs for Women in Delhi at Radiance Hair Studio. Find the perfect wig for any occasion, tailored to match your style.",
    keywords: ["Wigs for Women in Delhi"],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/wigs-for-womens.png",
      "https://www.radiancehairstudio.com/wigs-for-womens1.png",
      "https://www.radiancehairstudio.com/wigs-for-womens2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "disambiguatingDescription": "Wigs for Women in Delhi",
    "additionalType": "https://www.radiancehairstudio.com/wigs-for-womens.php",
    "category": "https://www.radiancehairstudio.com/wigs-for-womens.php",
    "description": "Explore a stunning range of Wigs for Women in Delhi at Radiance Hair Studio. Find the perfect wig for any occasion, tailored to match your style.",
    "serviceType": "Wig for Women's"
  };  

  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
      img: "/wigs-for-womens.png",
      content: (
        <div>
          <h2>Top Quality WIG for Women's</h2>
          <p>
          Women have always chosen to reflect their personality and vibes through what
          they wear? and how they wear! You are what you wear, that's all!! However, the lifestyle we select may have different plans.. Hair fall and thinning are the two most common Devils enough to ruin the MANE MOOD.
          </p>
          <h2>Master-Crafted Wigs Only in Radiance!</h2>
          <p>
          Oh, Wait! WE ARE BORN FIGHTERs too. And to fight all the hair hazard WIGS to rescue! Be it the <a href="/about.php" style={{ color: "#373737"}}>Lifestyle</a> Monster or that Devil Disease that's ruining the scenario, RADIANCE has got your back.
          </p>
          <p>
          Obtain the greatest WIGS that can represent who you are! At RADIANCE we help our brave women to get what they DESERVE. Short, long, thick or thin, black or colored, you say and we have it, but only the ALL NATURAL HUMAN HAIR! 
          </p>
          <p>
          Fakeness is not something one should ever carry inside out. The same reason leads RADIANCE to offer the natural WIGS to the REAL women. 
          </p>
        </div>
      ),
    },
  ];

  const sectionS3 = [
    {
      smallImg: "/wigs-for-womens1.png",
      largeImg: "/wigs-for-womens2.png",
      content: (
        <div>
          <h2>Benefits: The Big Blessings!</h2>
          <p>
          The DIVINE heard it all and thus WIGS! WIGs are indeed blessings in disguise. Letting you shower your love also with the natural feel can be named sheer LOVE. Our hair enhances our physical attractiveness, which gives us self-confidence and empowers us to take over the world. Here are some of them.
          </p>
          <ul>
            <li>
              <b>STYLE</b>- Your hair is a reflection of your personality, shaping how you present yourself to the world. A well-styled mane enhances your overall appearance. You can quickly and easily get the ideal look with human hair wigs in delhi !
            </li>
            <li>
              <b>NEED</b>- Everyone possesses unique qualities, but not all are blessed with naturally voluminous hair. A wig provides the perfect solution, fulfilling both aesthetic and personal grooming needs while boosting confidence.
            </li>
            <li>
              <b>LOOK</b>- Your appearance speaks volumes about your personality and role. Whether for a professional setting or a personal transformation, wigs offer the flexibility to switch up your style while maintaining versatility.
            </li>
            <li>
              <b>Longevity</b>- Investing in a wig is not just about style—it’s about value. Natural human hair wigs are durable, ensuring a longer lifespan while being a cost-effective choice in the long run.
            </li>
          </ul>
          <p>
          At RADIANCE, we give our visitors the finest. We hope that wearing a premium, all-natural human <a href="/hair-replacement-for-men.php" style={{ color: "#373737"}}>hair wig</a> will improve your experience.
          </p>
        </div>
      ),
    },
  ];

  const sectionS4 = [
    {
      title: "FAQs: Answer Yourself!",
      description:
        "Wearing a WIG is not something we are habitual with. And the money matters may make you think about several questions. We are here to answer all of that. ",
      description2:
        "We hope this clears up your questions! RADIANCE HAIR STUDIO is here to assist you with expert guidance and a free consultation. Let us assist you in locating the ideal wig!",
      faq: [
        {
          title:
            "Do I need a wig if I have partial hair loss or thinning hair?",
          description:
            "There are several wigs available that potentially hide the thinning hairline or hair loss areas and one can hardly identify the wig as it blends seamlessly with your existing hair. It makes your hair look flawless and gorgeous.Additionally, the texture and finish of our wigs will be natural because they are composed entirely of real human hair.",
        },
        {
          title: "Can I style my wig?",
          description:
            "Absolutely! Like real hair, your wig can be styled in the same way. However, to maintain durability, proper maintenance is necessary.. Avoid excessive heat or harsh styling techniques. At RADIANCE, we also offer a wide collection of pre-customized premium wigs for a hassle-free experience.",
        },
        {
          title: "What type of wigs does RADIANCE offer?",
          description:
            "We provide a variety of high-quality wigs, but we highly recommend those made from 100% natural human hair for the most realistic look and feel. At RADIANCE, we also customize wigs to match your personal preferences, ensuring a perfect fit.",
        },
        {
          title: "Can I choose a custom color for my wig?",
          description:
            "Yes! We offer a wide selection of wigs in different styles and shades. Our hair experts will help you choose the ideal color and design that complements your look. If you want a custom shade, we can personalize the color to match your natural hair seamlessly.",
        },
      ],
    },
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Wig for Women's" banner="/wig-for-women.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Top Quality WIG for Women's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default WigforWomen;
